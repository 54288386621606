import React, { useState } from "react";

const GtmContext = React.createContext();

const GtmProvider = props => {
  const [showBanner, setShowBanner] = useState(false);

  return (
    <GtmContext.Provider
      value={{
        container_id: props.container_id,
        showBanner: showBanner,
        setShowBanner: val => setShowBanner(val),
      }}
    >
      {props.children}
    </GtmContext.Provider>
  );
};

export default GtmContext;

export { GtmProvider };
