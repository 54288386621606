import * as React from "react";
import { PrismicProvider } from "@prismicio/react";
import { Link } from "gatsby";
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews";

import { linkResolver } from "./src/linkResolver";
import "./src/styles/global.css";

import { ThemeProvider } from "styled-components";

import { GtmProvider } from "./src/context/GtmContext";
import Theme from "./src/themes/theme";

const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO,
    linkResolver,
    componentResolver: {
      homepage: React.lazy(() => import('./src/pages/index')),
      page: React.lazy(() => import('./src/pages/{prismicPage.uid}')),
      blog_post: React.lazy(() => import("./src/pages/blog/{prismicBlogPost.uid}")),
    },
  },
]

export const wrapRootElement = ({ element }) => {
  return (
    <GtmProvider container_id={process.env.GATSBY_GTM_CONTAINER_ID}>
      <PrismicProvider
        linkResolver={linkResolver}
        internalLinkComponent={({ href, ...props }) => (
          <Link to={href} {...props} />
        )}
      >
        <PrismicPreviewProvider
          repositoryConfigs={repositoryConfigs}
        >
          <ThemeProvider theme={Theme}>{element}</ThemeProvider>
        </PrismicPreviewProvider>
      </PrismicProvider>
    </GtmProvider>
  );
};
