export const screens = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  mobileLandscape: "576px",
  tablet: "768px",
  laptop: "1024px",
  laptopM: "1280px",
  laptopL: "1441px",
  desktop: "2560px",
};

export const theme = {
  fonts: {
    main: "Kobe, sans-serif",
  },
  fontSizes: {
    xs: "0.75rem", // "12px",
    sm: "0.875rem", // "14px",
    base: "1rem", // "16px",
    lg: "1.125rem", // "18px",
    xl: "1.25rem", // "20px",
    "2xl": "1.5rem", // "24px",
    "3xl": "1.875rem", // "30px",
    "4xl": "2.25rem", // "36px",
    "5xl": "3rem", // "48px",
    "6xl": "3.75rem", // "60px",
    "7xl": "4.5rem", // "72px",
    "8xl": "6rem", // "96px",
    "9xl": "8rem", // "128px",
  },
  colors: {
    aqua: "#00FFFF",
    charcoal: "#2F2F2F",
    white: "#FFFFFF",
    anti_flash_white: "#F3F3F3",
    fuchsia: "#FF00FF",
    yellow: "#FF0000",
    green: "#00FF00",
  },
  gradient:
    "linear-gradient(to top right, hsl(180deg 100% 50%) 0%, hsl(193deg 100% 50%) 25%, hsl(304deg 100% 50%) 100%)",
  breakpoints: {
    mobileS: `only screen and (min-width: ${screens.mobileS})`,
    mobileM: `only screen and (min-width: ${screens.mobileM})`,
    mobileL: `only screen and (min-width: ${screens.mobileL})`,
    mobileLandscape: `only screen and (min-width: ${screens.mobileLandscape})`,
    tablet: `only screen and (min-width: ${screens.tablet})`,
    laptop: `only screen and (min-width: ${screens.laptop})`,
    laptopM: `only screen and (min-width: ${screens.laptopM})`,
    laptopL: `only screen and (min-width: ${screens.laptopL})`,
    desktop: `only screen and (min-width: ${screens.desktop})`,
  },
  spacings: {
    xxs: ".25rem",
    xs: ".5rem",
    small: "1rem",
    medium: "2rem",
    large: "3rem",
    xl: "4rem",
    xxl: "6rem",
  },
  sizes: {
    MobileSNavHeight: "64px",
    MobileNavHeight: "90px",
    TabletNavHeight: "105px",
    LaptopNavHeight: "125px",
    LaptopMNavHeight: "150px",
    DesktopNavHeight: "225px",
    container: "1680px",
    BlockMaxWidth: "900px",
  },
};

export default theme;
