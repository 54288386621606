exports.linkResolver = doc => {
  // URL for a page type
  if (doc.link_type === "Document") {
    if (doc.type === "blog_post") {
      return `/blog/${doc.uid}/`;
    }

    return `/${doc.uid || ""}`;
  } else if (doc.link_type === "Web") {
    return doc.url;
  }

  // Backup for all other types
  return "/";
};
